import { ButtonLink, useContracts } from "@synota-io/synota-shared-ui";
import { ExposureCalculatorCard } from "../components/ExposureCalculatorCard";
import { generatePath, useParams } from "react-router-dom";
import { Box, Card, CardContent, Slide, Stack, Typography } from "@mui/material";
import { CONTRACT_PATH } from "../../../paths";
import { ArrowLeft } from "@mui/icons-material";

export const ContractExposurePage = () => {
  const { contractId } = useParams();
  const { contract, isLoading } = useContracts(contractId);

  if (isLoading || !contract) {
    return null;
  }

  return (
    <Slide direction="up" in>
      <Card>
        <CardContent>
          <Stack direction="row" mb={8}>
            <Typography variant="h5">A/R Exposure Calculator</Typography>
            <Box flexGrow={1} />
            <ButtonLink
              startIcon={<ArrowLeft />}
              size="small"
              variant="text"
              to={generatePath(CONTRACT_PATH, { contractId: contract.uuid })}
            >
              Back to Contract
            </ButtonLink>
          </Stack>
          <ExposureCalculatorCard contract={contract} />
        </CardContent>
      </Card>
    </Slide>
  );
};
